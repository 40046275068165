import React, { useState, useEffect } from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons/faLongArrowRight"
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark"

import { Contact } from "../Forms"

const TopPopup = ({ pageUrl }) => {
  const [animation, setAnimation] = useState("")
  const [hasForm, setHasForm] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimation("slide-down")
    }, 1)

    return () => {
      setAnimation("slide-up-out")
    }
  }, [])

  const handleSuccess = () => {
    setFormSuccess(true)
    setTimeout(() => {
      setAnimation("slide-up-out")
    }, 3000)
  }

  const mainClasses = classNames("pu pu__top", animation, {
    "has-form": hasForm
  })
  return (
    <div className={mainClasses}>
      {!hasForm && (
        <>
          <FontAwesomeIcon
            onClick={() => setAnimation("slide-up-out")}
            className="pu__close"
            icon={faXmark}
          />
          <h6>Outperform Your Competitors</h6>
          <div className="pu__toggle-form" onClick={() => setHasForm(true)}>
            <FontAwesomeIcon className="pu__arrow" icon={faLongArrowRight} />
            <span>Let's Talk</span>
            <FontAwesomeIcon className="pu__arrow" icon={faLongArrowLeft} />
          </div>
        </>
      )}

      {hasForm && (
        <div className="pu__top-form">
          <FontAwesomeIcon
            onClick={() => setAnimation("slide-up-out")}
            className="pu__close"
            icon={faXmark}
          />
          <div>
            <h6>Outperform Your Competitors</h6>
            <p>
              A member of our team will contact you within one business day.
            </p>
          </div>
          <div>
            {formSuccess ? (
              <h3 className="color-white"> Thank You!</h3>
            ) : (
              <Contact
                type="light"
                pageUrl={pageUrl}
                classNames="pu__form--light"
                onSuccess={handleSuccess}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default TopPopup
