import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import { LeftPopup } from "../components/Popups"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import NuvoImage from "../components/NuvoImage"
import ImageMeta from "../components/ImageMeta"
import { Section, Container } from "../components/Layouts"
import ButtonGroupMap from "../components/Buttons/ButtonGroupMap"
import Button from "../components/Buttons/Button"
import { MarketingAnalysisFormTwo } from "../components/Forms"

const TopAgency = ({ data, path, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      {/* <LeftPopup pageUrl={location.pathname} startHidden /> */}

      <div className="top-agency-page">
        <h1>{post.heading}</h1>

        <NuvoImage
          wrapperClassName="top-agency-page__hero-img"
          publicId={post.heroImage}
        />

        <div className="top-agency-page__grid">
          <div className="top-agency-page__content">
            {post.blurbs.map((item, i) => {
              let target = {}
              if (item.button?.opensNewTab) {
                target["target"] = "_blank"
              }

              return (
                <div key={i} className="top-agency-page__single-blurb">
                  <MarkdownViewer markdown={item.text} />

                  {item.button?.href && item.button.opensNewTab ? (
                    // <Button
                    //   buttonText={item.button.buttonText}
                    //   href={item.button.href}
                    //   title={item.button.titleTag}
                    //   external={item.button.opensNewTab}
                    //   className="mt-1"
                    // />
                    <></>
                  ) : (
                    <Button
                      buttonText={item.button?.buttonText}
                      href={item.button?.href}
                      title={item.button?.titleTag}
                      anchor
                      className="mt-1"
                    />
                  )}
                </div>
              )
            })}
          </div>

          <div className="top-agency-page__image">
            <ImageMeta
              publicId={post.imageId}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
            />
          </div>
        </div>

        <div className="mt-3">
          {post.bodySections.map((section, i) => {
            if (section.type === "textSection") {
              return <MarkdownViewer key={i} markdown={section.text} />
            }

            if (section.type === "buttonSection") {
              return (
                <Button
                  className="mt-3 mb-3"
                  key={i}
                  buttonText={section.button.buttonText}
                  href={section.button.href}
                  destination={section.button.destination}
                  appearance={section.button.appearance}
                />
              )
            }
            return null
          })}
        </div>
      </div>
      <Section colorBack className="relative">
        <Container small>
          <MarketingAnalysisFormTwo
            id="top-agency-contact-form"
            origin={location.href}
            phone="(415) 304-8425"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default TopAgency

export const pageQuery = graphql`
  query topAgencyPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        heroImage
        blurbs {
          text
          button {
            buttonText
            href
            opensNewTab
            titleTag
          }
        }
        bodySections {
          type
          text
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        imageId
      }
    }
  }
`
