import React, { useState, useEffect } from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons/faLongArrowRight"
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark"
import { Contact } from "../Forms"

const LeftPopup = ({ pageUrl, startHidden }) => {
  const [animation, setAnimation] = useState("")
  const [hasForm, setHasForm] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    // slide in popup by default.
    if (!startHidden) {
      setTimeout(() => {
        setAnimation("slide-right")
      }, 1)
    }

    // slide form popup back in when mouse leaves browser window
    const mouseout = () => {
      setAnimation("slide-right")
    }
    if (!formSuccess) {
      document.documentElement.addEventListener("mouseleave", mouseout)
    }

    return () => {
      document.documentElement.removeEventListener("mouseleave", mouseout)
      setAnimation("slide-left-out")
    }
  }, [])

  const handleSuccess = () => {
    setFormSuccess(true)
    setTimeout(() => {
      setAnimation("slide-left-out")
    }, 3000)
  }

  const mainClasses = classNames("pu pu__left", animation, {
    "has-form": hasForm
  })
  return (
    <div className={mainClasses}>
      <FontAwesomeIcon
        onClick={() => setAnimation("slide-left-out")}
        className="pu__close"
        icon={faXmark}
      />
      {!hasForm && (
        <>
          <div>
            <h6>
              Outperform Your <br />
              Competitors
            </h6>
            <p className="mt-1 color-white">
              Get the insights and data-driven strategy you need to dominate
              your market. Request your marketing analysis today.
            </p>
          </div>
          <button
            type="button"
            aria-label="open form"
            className="round-button"
            onClick={() => setHasForm(true)}>
            Get Marketing Analysis
          </button>
        </>
      )}

      {hasForm && (
        <div className="pu__left-form">
          <div>
            <h6>
              Outperform Your <br />
              Competitors
            </h6>
          </div>
          <div className="mt-3">
            {formSuccess ? (
              <h3 className="color-white"> Thank You!</h3>
            ) : (
              <Contact
                pageUrl={pageUrl}
                classNames="pu__form--dark"
                onSuccess={handleSuccess}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default LeftPopup
